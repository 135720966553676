"use strict";

import "./js/inc/_jquery-global.js";
import "./js/inc/lite-yt-embed.js";
import "./scss/style.scss";
import ScrollHint from 'scroll-hint';

window.addEventListener("load", () => {
  
  /* TOPに戻るボタン スクロールエフェクト
  -------------------------------------------- */
  $('.js-pageTop').on('click', function () {
    $('body,html').animate({ scrollTop: 0 }, 300, 'swing');
    return false;
  });

  /* メガメニュー
  ----------------------------- */
  const headerNavChildHooks = document.querySelectorAll('.menuHookPC');
  headerNavChildHooks.forEach((hook) => {
    const headerNavItemMenu = hook.querySelector('.js-headerNavChild a');
    const headerNavChild = hook.querySelector('.js-headerNavChild');
    const bodyElement = document.querySelector('body');
    if(!headerNavChild.classList.contains('is-open')) {
      hook.addEventListener('mouseover', () => {
        hook.classList.add('is-open');
        headerNavChild.classList.add('is-open');
        bodyElement.classList.add('is-open');
        headerNavItemMenu.classList.add('is-open');
      });
      hook.addEventListener('mouseleave', () => {
        hook.classList.remove('is-open');
        headerNavChild.classList.remove('is-open');
        bodyElement.classList.remove('is-open');
        headerNavItemMenu.classList.remove('is-open');
      });
    }
  });

  /* スマホメニュー
  ----------------------------- */
  const navBtn = document.querySelector(".js-navBtn");
  const headerNav = document.querySelector(".js-headerNav");
  const headerNavMenu = document.querySelectorAll(".js-headerNavMenu");
  const bodyElement = document.querySelector("body");
  const headerSubNavTopItem = document.querySelector(".js-headerSubNavTopItem");

  const openMenu = () => {
    const tl = gsap.timeline();
    tl.to(bodyElement, { onStart: () => bodyElement.classList.add("is-open") })
      .to(navBtn, { onStart: () => navBtn.classList.add("is-open") }, '<')
      .fromTo(headerNav, {x:"100%", autoAlpha:0}, {x:0, autoAlpha:1, duration: 0.4, ease: "ease-in"}, '<')
      .fromTo(headerNavMenu, {autoAlpha:0}, {autoAlpha:1, ease:'ease-in'}, '<')
      .fromTo(headerSubNavTopItem, {autoAlpha:0}, {autoAlpha:1, ease:'ease-in'}, '<');
    return tl;
  }
  
  const closeMenu = () => {
    const tl = gsap.timeline();
    tl.to(bodyElement, { onStart: () => bodyElement.classList.remove("is-open") })
      .to(navBtn, { onStart: () => navBtn.classList.remove("is-open") }, '<')
      .fromTo(headerNav, {x:0}, {x:"100%", duration: 0.6, ease: "ease-in"}, '<')
      .fromTo(headerNavMenu, {autoAlpha:1}, {autoAlpha:0}, '<')
      .fromTo(headerSubNavTopItem, {autoAlpha:1}, {autoAlpha:0}, '<');
    return tl;
  }
  
  navBtn.addEventListener("click", () => {
    if(navBtn.classList.contains('is-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  /* header 固定・変化
  ----------------------------- */
  let scrollFlg = false;

  $(window).on("scroll", function () {
    let currentPos = $(window).scrollTop();

    if (currentPos > 300) {
      $(".js-header").addClass('is-fixed');
      scrollFlg = true;
    } else if (!scrollFlg | currentPos == 0) {
      $(".js-header").removeClass('is-fixed');
    }
  });

  /* アコーディオンメニュー
  ----------------------------- */
  $(function(){
    $('.menuHookSP span').on('click', function(){
      $(this).toggleClass("is-open");
      $(this).next('.js-accordionContents').slideToggle(350);
    });
  });

  /* hrefがないaタグをspanに変換
  ----------------------------- */
  const links = document.querySelectorAll('.header__navPC a:not([href]), .header__navSP a:not([href]), .footer__navPC a:not([href]), .footer__navSP a:not([href])');

  links.forEach(function(link) {
      const span = document.createElement('span');
      span.innerHTML = link.innerHTML;
      span.classList.add('noLinkMenu');

      link.parentNode.replaceChild(span, link);
  });

  /* FVスライダー
  ----------------------------- */
  const fvSlider = document.querySelector('.js-fvSlider');
  if(fvSlider) {
    const slideLength = document.querySelectorAll('.fv__slide').length;
    if(slideLength > 1) {
      const fvSlider = new Swiper('.js-fvSlider', {
        loop: true,
        autoplay: true,
        slidesPerView: 1,
        allowTouchMove: false,
        speed: 2000,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          waitForTransition: false,
        },
      });
    }
  }

  /* 参加者の声 スライダー
  ----------------------------- */
  const voiceSlider = document.querySelector('.js-voiceSlider');
  const voiceSliderElement = document.querySelector('.p-voiceSlider');
  if(voiceSlider) {
    const slideLength = document.querySelectorAll('.p-voiceCard').length;
    if(slideLength > 1) {
      const voiceSwiper = new Swiper('.js-voiceSlider', {
        loop: true,
        speed: 400,
        slidesPerView: 1,
        navigation: { 
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          576: {
            spaceBetween: 20,
            slidesPerView: 2,
          },
          768: {
            spaceBetween: 30,
            slidesPerView: 2,
          },
          1280: {
            spaceBetween: 50,
            slidesPerView: 2,
          }
        }
      });
    } else {
      voiceSliderElement.classList.add('is-stop');
    }
  }

  /* フォトギャラリー モーダルスライド
  ----------------------------- */
  const modal = document.querySelector(".js-photoModal");
  if(modal) {
    // モーダルを表示するボタンを全て取得
    const openModalBtns = document.querySelectorAll(".js-openModal");
    // モーダルを閉じるボタンを全て取得
    const closeModalBtns = document.querySelectorAll(".js-closeModal");
    const bodyElement = document.querySelector("body");
  
    // Swiperの設定
    const swiper = new Swiper(".js-photoSlider", {
      loop: true,
      speed: 400,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      spaceBetween: 30,
    });

    // モーダルを表示するボタンをクリックしたとき
    openModalBtns.forEach((openModalBtn) => {
      openModalBtn.addEventListener("click", () => {
        // data-slide-indexに設定した属性値を取得
        const dataIndex = openModalBtn.getAttribute('data-slide-index');
        // 一致するdata-swiper-indexを持つスライドを探す
        const matchingSlide = Array.from(document.querySelectorAll('.swiper-slide')).findIndex(slide => slide.getAttribute('data-swiper-index') === dataIndex);
        if (matchingSlide >= 0) {
          swiper.slideTo(matchingSlide, 0); // スライド番号とスピード（0で即時）
          modal.classList.add("is-active");
          bodyElement.classList.add("is-active");
        }
      });
    });
  
    // モーダルを閉じるボタンをクリックしたとき
    closeModalBtns.forEach((closeModalBtn) => {
      closeModalBtn.addEventListener("click", () => {
        modal.classList.remove("is-active");
        bodyElement.classList.remove("is-active");
      });
    });
  }

  /* よくある質問
  ----------------------------- */
  const faq = document.querySelector('.js-faq');
  $(".js-faqBox:first-of-type .js-faqAnswer").css("display", "block");
  $(".js-faqBox:first-of-type .js-faqAnswer").addClass("is-faq-open");
  $(".js-faqBox:first-of-type .js-faqQuestion").addClass("is-faq-open");

  if(faq){
    $(".js-faqQuestion").on("click", function () {
      $(this).next().slideToggle(350);
      $(this).next().toggleClass("is-faq-open");
      $(this).toggleClass("is-faq-open");
    });
  }

  /* 事業報告 InfiniteScroll
  ----------------------------- */
  const reportList = document.querySelector('.report__list');
  if(reportList) {
    const infScroll = new InfiniteScroll( '.report__list', {
      append: '.report__item',             // 各記事の要素
      path: '.next_posts_link a',  // 次のページへのリンク要素を指定
      hideNav: '.next_posts_link', // 指定要素を非表示にする（ここは無くてもOK）
      button: '.view-more-button', // 記事を読み込むトリガーとなる要素を指定
      scrollThreshold: false,      // スクロールで読み込む：falseで機能停止（デフォルトはtrue）
      status: '.page-load-status', // ステータス部分の要素を指定
      history: 'false'             // falseで履歴に残さない
    });
  }

  /* もっと見るボタン
  -------------------------------------------- */
  $(function(){
    $('.b-more__btn').on('click', function(){
      //クリックしたボタンの親要素にクラスを付与
      const target = $(this).closest('.b-more');
      target.toggleClass('active');
  
      //クラスの有無で条件分岐
      if(target.hasClass('active')){
        //スクロール領域も含めた高さを取得・付与する
        const height = target.get(0).scrollHeight;
        target.css('height', height);
        //テキストを変更する
        $(this).addClass('is-open');
        $(this).find('.text').text('閉じる');
      }else{
        //高さを戻す
        target.css('height', '');
        //テキストを戻す
        $(this).removeClass('is-open');
        $(this).find('.text').text('もっと見る');
      }
    });
  });

  /* スマイルサポート 注意事項確認チェックボックス
  -------------------------------------------- */
  const agreeCheckbox = document.querySelector('.js-paymentAgree');
  const paymentButtons = document.querySelectorAll('.js-paymentBtn');
  const paymentError = document.querySelector('.js-paymentError');

  // ボタンのクリックイベントを監視
  paymentButtons.forEach(function(button) {
    button.addEventListener('click', function(event) {
      if (!agreeCheckbox.checked) {
        event.preventDefault(); // フォームの送信を防止
        alert('APCCスマイルサポート注意事項（上記）を確認して、チェックを入れてください。')
      }
    });
  });

  /* 数字で見るAPCC カウントアップ
  ----------------------------- */
  const countUpTriggers = document.querySelectorAll('.js-countUpTrigger');
  const countUpTargets = document.querySelectorAll('.js-countUpTarget');

  countUpTriggers.forEach((countUpTrigger, index) => {
    let countUpTarget = countUpTargets[index];
    const elementDic = {
      from:countUpTarget.dataset.from,
      to:countUpTarget.dataset.to
    };
    
    const elementNum = {count:elementDic.from};
    const duration = countUpTrigger.classList.contains('js-countUpTriggerShort') ? 0.5 : 1;

    gsap.to(elementNum, {
      count:elementDic.to,
      duration:duration,
      ease:'none',
      scrollTrigger: {
        trigger:countUpTrigger,
        start: 'top bottom',
      },
      onUpdate:() => {
        if (elementDic.to % 1 !== 0) {
          countUpTarget.textContent = elementNum.count.toFixed(1).toLocaleString();
        } else {
          countUpTarget.textContent = Math.floor(elementNum.count).toLocaleString();
        }
      }
    });
  })

  const fadeInIcons = document.querySelectorAll('.js-fadeInSupportIcon');
  fadeInIcons.forEach((fadeInIcon) => {
    gsap.fromTo(fadeInIcon,
      {
        autoAlpha: 0,
        y: 20
      },
      {
        autoAlpha: 1,
        y: 0,
        duration: 2.5,
        ease: "power4.out",
        scrollTrigger: {
          trigger: fadeInIcon,
          start: 'top bottom',
        }
      }
    );
  })

  /* TOPに戻るボタン 表示・非表示
  -------------------------------------------- */
  window.addEventListener('scroll', () => {
    const pageTop = document.querySelector('.js-pageTop');
    const currentY = window.pageYOffset;

    if(currentY >= 200) {
      setTimeout(() => {
        pageTop.style.opacity = 1;
        pageTop.style.visibility = 'visible';
      }, 1);
    } else {
      setTimeout(() => {
        pageTop.style.opacity = 0;
        pageTop.style.visibility = 'hidden';
      }, 1);
    }
  });

  /* TOPに戻るボタン footer内で非表示・表示
  -------------------------------------------- */
  $(document).ready(function() {
    const $backToTop = $('.js-pageTop');
    const $footer = $('.js-footer');
    let isScrolling;

    $(window).on('scroll', function() {
      const footerTop = $footer.offset().top;
      const scrollTop = $(window).scrollTop();
      const windowHeight = $(window).height();

      // スクロールが停止した場合の動作をセット
      window.clearTimeout(isScrolling);
      
      if (scrollTop + windowHeight > footerTop) {
        $backToTop.removeClass('is-hidden');  // スクロール時はボタンを表示

        // スクロールが停止したらボタンを非表示にする
        isScrolling = setTimeout(function() {
          $backToTop.addClass('is-hidden');
        }, 1000);
      } else {
        $backToTop.removeClass('is-hidden');  // フッターの外ではボタンを表示
      }
    });
  });
});

/* WPカラム タイトルの高さ統一
-------------------------------------------- */
function equalizeCardTitleHeights() {
  // 各.wp-block-columns要素に対して処理を実行
  document.querySelectorAll('.wp-block-columns').forEach(function (columns) {
    // 現在のセクション内のカードタイトルを取得
    var cardTitles = columns.querySelectorAll('.b-card__title');

    // 最大の高さをリセット
    var maxHeight = 0;

    // 一旦すべてのタイトルの高さを'auto'に設定して自然な高さを取得
    cardTitles.forEach(function (title) {
      title.style.height = 'auto';
    });

    // 最大の高さを取得
    cardTitles.forEach(function (title) {
      var titleHeight = title.offsetHeight;
      if (titleHeight > maxHeight) {
        maxHeight = titleHeight;
      }
    });

    // すべてのタイトルに最大の高さを適用
    cardTitles.forEach(function (title) {
      title.style.height = maxHeight + 'px';
    });
  });
}

// ドキュメントの読み込みが完了したら関数を実行
document.addEventListener('DOMContentLoaded', equalizeCardTitleHeights);

// ウィンドウのリサイズ時にも高さを再計算
window.addEventListener('resize', equalizeCardTitleHeights);


/* ScrollHint
-------------------------------------------- */
document.addEventListener('DOMContentLoaded', function() {
  const tables = document.querySelectorAll('.wp-block-flexible-table-block-table table');

  if(tables) {
    tables.forEach((table) => {
      table.classList.add('js-scrollable');
    })
  
    // ScrollHint初期化
    new ScrollHint('.js-scrollable', {
      scrollHintIconAppendClass: 'scroll-hint-icon-white', // 背景色変更
      applyToParents: true, // 親要素にJS適用（テーブル複数対策）
      i18n: {
        scrollable: 'スクロールできます' // テキスト変更
      }
    });
  }
});